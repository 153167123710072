<template>
    <div style="color: #333;">
        <el-tabs>
            <el-tab-pane label="Certificate">
                <el-button type="primary" v-if="awards.length > 0" @click="exportPDF('showTotal')"
                    size="medium">Download
                    the
                    certificate</el-button>
                <p v-if="showNoAwards == true" style="margin-left: 20px;">You did not receive an award for
                    this competition</p>
                <div id="showTotal">
                    <div v-for="(item, index) in awards" :key="index" class="certContainer16"
                        style="margin-bottom:10px;">
                        <img src="../../assets/image/aeo_cert.png" class="modelCert" />
                        <div class="student">{{ upfirstName(studentInfo.student_givenName_pinyin) + ' ' +
                            upfirstName(studentInfo.student_lastName_pinyin) }}</div>
                        <div class="school">{{ accountInfo.account_nameEN }}</div>
                        <div class="award">{{ item.award_lable }}</div>
                    </div>
                </div>
            </el-tab-pane>
            <el-tab-pane label="Transcript">
                <el-button type="primary" @click="exportPDF('showTranscript')" size="medium">Download the
                    transcript</el-button>
                <div id="showTranscript">
                    <div v-for="(item, index) in transcripts" :key="index" class="transcriptContainer2"
                        style="margin-bottom:10px;">
                        <img src="../../assets/image/aeo_transcript.png" class="model_p" />
                        <div class="name">{{ upfirstName(studentInfo.student_givenName_pinyin) + ' ' +
                            upfirstName(studentInfo.student_lastName_pinyin) }}</div>

                        <div class="account">{{ accountInfo.account_nameEN }}</div>

                        <div class="object_score">{{ item.object_exam_score }}</div>
                        <div class="case_score">{{ item.case_score }}</div>
                        <div class="total_score">{{ item.total_score }}</div>
                    </div>
                </div>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
import { getStudentTranscriptsByApplication, getStudentAwardsByApplication } from '../../api/eae'

export default {
    name: 'index',
    data() {
        return {
            id: this.$route.query.id,
            awards: [],
            transcripts: [],
            projectInfo: {},
            studentInfo: {},
            accountInfo: {},
            showNoAwards: false
        }
    },
    mounted() {
        //测试使用
        // this.id = '66b38e54a4640bcf788b4570'
        this.fetchData()
    },
    methods: {


        formatGradeInfo(grade) {
            if (grade >= 4) {
                return 'Excellence'
            }
            if (grade >= 3) {
                return 'Distinction'
            }
            if (grade >= 2) {
                return 'Great Distinction'
            }
            return 'Highest Distinction'
        },

        fetchData() {

            getStudentAwardsByApplication(this.id).then(res => {
                if (res.data.code === 0) {
                    this.awards = res.data.data.awards
                    if(this.awards.length <= 0){
                        this.showNoAwards = true
                    }
                }
            })

            getStudentTranscriptsByApplication(this.id).then((res) => {
                let result = res.data.data
                this.projectInfo = result.project_info
                this.studentInfo = result.student_info
                this.accountInfo = result.account_info

                if (this.projectInfo.project_code == '2024AEO') {
                    let object_exam_score = 0
                    let case_score = 0
                    let totalScore = 0
                    for (let i = 0; i < result.scores.length; i++) {
                        let score = result.scores[i]
                        totalScore += score.total_score
                        if (score.application_event == 'Objective Question Exam') {
                            object_exam_score = score.total_score
                        } else {
                            case_score += score.total_score
                        }
                    }
                    let newScore = {
                        object_exam_score: object_exam_score,
                        case_score: case_score,
                        total_score: totalScore
                    }
                    this.transcripts.push(newScore)
                }
            })
        },

        upfirstName(name) {

            if (name == 'João Pedro') {

                return name
            }
            if (name == 'AGYEN-FREMPONG') {
                return name
            }

            return name.toLowerCase().replace(/\b([\w|‘]+)\b/g, function (word) {
                //return word.slice(0, 1).toUpperCase() + word.slice(1);
                return word.replace(word.charAt(0), word.charAt(0).toUpperCase());
            });

            if (name) {
                //         const transferName = name.toLowerCase()
                //   return transferName.charAt(0).toUpperCase() + transferName.slice(1)     



                if (name.indexOf(" ") != -1) {
                    var newarr, newarr1;
                    newarr = name.toLowerCase().split(" ");
                    for (var i = 0; i < newarr.length; i++) {
                        newarr[i] = newarr[i][0].toUpperCase() + newarr[i].substring(1, newarr[i].length);
                    }
                    newarr1 = newarr.join(" ");
                    return newarr1;
                } else {
                    const transferName = name.toLowerCase();
                    return transferName.charAt(0).toUpperCase() + transferName.slice(1)
                }
            } else {
                return name
            }

        },
        exportPDF(id) {

            var style = document.createElement('style');
            if (id == 'showTotal') {
                style.innerHTML = "@page{size:A4 landscape;}";
            } else {
                style.innerHTML = "@page{size:A4 portrait;margin: auto 0mm}";
                document.getElementsByTagName("body")[0].style.zoom = 1
            }

            window.document.head.appendChild(style)

            const printData = document.getElementById(id).innerHTML


            this.PageSetup_Null();//把页眉页脚设置为空


            window.document.body.innerHTML = printData // 把 html 里的数据 复制给 body 的 html 数据 ，相当于重置了 整个页面的 内容
            window.print() // 开始打印


            //   const printData = document.getElementById(id).innerHTML

            //   window.document.body.innerHTML = printData // 把 html 里的数据 复制给 body 的 html 数据 ，相当于重置了 整个页面的 内容
            //   window.print() // 开始打印
        },
        PageSetup_Null() {
            var HKEY_Root, HKEY_Path, HKEY_Key;

            HKEY_Root = "HKEY_CURRENT_USER";

            HKEY_Path = "\\Software\\Microsoft\\Internet Explorer\\PageSetup\\";

            try {
                var Wsh = new ActiveXObject("WScript.Shell");

                HKEY_Key = "header";

                Wsh.RegWrite(HKEY_Root + HKEY_Path + HKEY_Key, "");

                HKEY_Key = "footer";

                Wsh.RegWrite(HKEY_Root + HKEY_Path + HKEY_Key, "");

            } catch (e) { }

        }
    }
}
</script>

<style lang="scss">
.certContainer16 {
    position: relative;

    .modelCert {
        width: 297mm;
        height: 208.7mm;
    }

    .student {
        position: absolute;
        top: 300px;
        width: 297mm;
        font-size: 35px;
        font-weight: 600;
        text-align: center;
    }

    .school {
        width: 297mm;
        position: absolute;
        top: 350px;
        text-align: center;
        font-size: 28px;
        font-weight: 350;
    }

    .award {
        width: 297mm;
        position: absolute;
        top: 435px;
        text-align: center;
        font-size: 32px;
        font-weight: 500;
    }

}

.transcriptContainer2 {
    position: relative;

    .model_p {
        width: 210mm;
        height: 295mm;
    }

    .name {
        position: absolute;
        width: 210mm;
        top: 480px;
        font-size: 42px;
        font-weight: 500;
        text-align: center;
    }

    .account {
        text-align: center;
        width: 210mm;
        position: absolute;
        top: 540px;
        font-size: 30px;
        font-weight: 400;
    }

    .object_score {
        position: absolute;
        top: 695px;
        left: 130px;
        font-size: 18px;
        font-weight: 400;
    }

    .case_score {
        position: absolute;
        top: 695px;
        left: 350px;
        font-size: 18px;
        font-weight: 400;
    }

    .total_score {
        position: absolute;
        top: 695px;
        left: 575px;
        font-size: 18px;
        font-weight: 400;
    }

}
</style>
